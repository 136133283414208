import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-google-map',
  standalone: true,
  imports: [],
  templateUrl: './google-map.component.html',
  styleUrl: './google-map.component.scss'
})
export class GoogleMapComponent {

  googleMapsUrl: SafeResourceUrl;


  constructor(private sanitizer: DomSanitizer) {
    // Sanitiza la URL para que sea segura
    this.googleMapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14731975.30119328!2d-119.66033935546875!3d25.648954430605574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa3a2a49b25788103%3A0x1075be8825c52127!2sFabrica%20de%20ropa%2C%20bordados%2C%20corte%20y%20grabado%20laser%20Camben!5e0!3m2!1ses-419!2smx!4v1734235939021!5m2!1ses-419!2smx'
    );
  }

}
