import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogComponent } from './blog/blog.component';
import { CartComponent } from './shopping/cart/cart.component';
import { ContactComponent } from './contact/contact.component';
import { ProductDetailsComponent } from './shopping/product-details/product-details.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './shopping/product/product.component';
import { CheckoutFormComponent } from './shared/components/checkout-form/checkout-form.component';
import { LoginComponent } from './login/login.component';
import { PaymentsComponent } from './payments/payments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { GoogleMapComponent } from './google-map/google-map.component';


//import {canActivate, redirectUnauthorizedTo} from '@angular/fire/auth-guard'

// const routes: Routes = [
//   {
//     path: '',
//     pathMatch: 'full',
//     redirectTo: '/login'
//   },
//   {
//     path: 'login',
//     component: LoginComponent
//   },
//   {
//     path: 'home',
//     component: HomeComponent,
//     ...canActivate(() => redirectUnauthorizedTo(['/login'])),
//     children: [
//       {
//         path: 'productos',
//         component: ProductComponent,
//         ...canActivate(() => redirectUnauthorizedTo(['/login']))
//       },
//     ]
//   },
// ];

const routes: Routes = [
  { path: '', redirectTo: '/product', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  //{ path: 'home', component: HomeComponent },
  { path: 'product', component: ProductComponent },
  { path: 'cart', component: CartComponent },
  { path: 'product/:id', component: ProductDetailsComponent },
  //{ path: 'blog', component: BlogComponent },
  //{ path: 'contact', component: ContactComponent },
  { path: 'secure-checkout', component: CheckoutFormComponent },
  { path: 'payments', component: PaymentsComponent },
  { path: 'pedidos', component: DashboardComponent },
  { path: 'order-details', component: OrderDetailsComponent },
  { path: 'ubicacion', component: GoogleMapComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
