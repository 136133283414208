<footer class="section-footer bg-white border-top">
  <div class="container">
    <section class="footer-main py-5">
      <div class="row">
        <aside class="col-12 col-sm-12 col-lg-4">
          <article class="me-lg-4">
            <img src="../../../assets/camben.png" class="logo-footer">
            <p class="mt-3"> © {{ date |date:'yyyy'}} CAMBEN. <br> All rights reserved. </p>
          </article>
        </aside>

        <aside class="col-6 col-sm-4 col-lg-2">
          <h2 class="title">Redes Sociales</h2>
          <div class="social-icons d-flex justify-content-center gap-3">
            <!-- Facebook -->
            <a [href]="facebookUrl" target="_blank" rel="noopener noreferrer" class="social-link">
              <i class="fab fa-facebook-f"></i>
            </a>
        
            <!-- TikTok -->
            <a [href]="tiktokUrl" target="_blank" rel="noopener noreferrer" class="social-link">
              <i class="fab fa-tiktok"></i>
            </a>
        
            <!-- YouTube -->
            <a [href]="youtubeUrl" target="_blank" rel="noopener noreferrer" class="social-link">
              <i class="fab fa-youtube"></i>
            </a>
        
            <!-- LinkedIn -->
            <a [href]="linkedinUrl" target="_blank" rel="noopener noreferrer" class="social-link">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </aside>
        

      </div>
    </section>




        <!--aside class="col-6 col-sm-4 col-lg-2">
          <h6 class="titl">Store</h6>
          <ul class="list-menu mb-3">
            <li> <a href="#">About us</a></li>
            <li> <a href="#">Find store</a></li>
            <li> <a href="#">Categories</a></li>
            <li> <a href="#">Blogs</a></li>
          </ul>
        </aside>
        <aside class="col-6 col-sm-4 col-lg-2">
          <h6 class="title">Information</h6>
          <ul class="list-menu mb-3">
            <li> <a href="#">Help center</a></li>
            <li> <a href="#">Money refund</a></li>
            <li> <a href="#">Shipping info</a></li>
            <li> <a href="#">Refunds</a></li>
          </ul>
        </aside>
        <aside class="col-6 col-sm-4  col-lg-2">
          <h6 class="title">Support</h6>
          <ul class="list-menu mb-3">
            <li> <a href="#"> Help center </a></li>
            <li> <a href="#"> Documents </a></li>
            <li> <a href="#"> Account restore </a></li>
            <li> <a href="#"> My Orders </a></li>
          </ul>
        </aside>
        <aside class="col-6 col-sm-4 col-lg-2">
          <h6 class="title">Our apps</h6>
          <a href="#" class="mb-2 d-inline-block"> <img src="../../../assets/btn-appstore.png" height="38"></a>
          <a href="#" class="mb-2 d-inline-block"> <img src="../../../assets/btn-market.png" height="38"></a>
        </aside>
      </div> 
    </section--> 

    <!--section class="py-3 d-flex justify-content-lg-between border-top">
      <div>
        <i class="bi bi-credit-card"></i>
        <i class="bi bi-paypal"></i>
        <i class="bi bi-credit-card"></i-->

        <!-- <i class="fab fa-lg fa-cc-amex"></i>
        <i class="fab fa-lg fa-cc-mastercard"></i>
        <i class="fab fa-lg fa-cc-paypal"></i> -->
      <!--/div>
      <nav class="dropup">
        <button class="dropdown-toggle btn d-flex align-items-center py-0" type="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          <img src="../../../assets/flag-usa.png" class="me-2" height="20">
          <span>English</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" href="#">urdu</a></li>

        </ul>
      </nav>

    </section-->


      
    
  </div> 

</footer>