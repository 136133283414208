import { Component } from '@angular/core';

@Component({
  selector: 'app-floating-whatsapp',
  standalone: true,
  imports: [],
  templateUrl: './floating-whatsapp.component.html',
  styleUrl: './floating-whatsapp.component.scss'
})
export class FloatingWhatsappComponent {

  whatsappNumber: string = '8110760979'; // Número de WhatsApp en formato internacional sin el signo "+"

  openWhatsApp(): void {
    const url = `https://wa.me/${this.whatsappNumber}`;
    window.open(url, '_blank');
  }

}
