import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date = new Date();

  facebookUrl: string = 'https://www.facebook.com/camben.elegance';
  tiktokUrl: string = 'https://www.tiktok.com/@uniformes.camben';
  youtubeUrl: string = 'https://www.youtube.com/@camben2418';
  linkedinUrl: string = 'https://www.linkedin.com/company/uniformes-camben/';

  constructor() { }
  ngOnInit(): void {
  }

}
